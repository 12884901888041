<template>
    <b-container class="prizes-container" fluid>
        <b-row align-h="start" class="header-top pb-2 ml-3 mr-3">
            <span class="text-title-first vertical-align mr-2">{{ $t('prizes.headerTitleFirstWord') }}</span>
            <span class="text-title-second">{{ $t('prizes.availablePointsLabel') }}: {{ availablePoints }}</span>
        </b-row>
        <div class="route-content">
            <div class="reward-coupons-container">
                <reward-card-container
                    v-for="reward in rewardLevels"
                    :key="reward.id"
                    :reward="reward"
                    :available-points="availablePoints"
                />
                <span v-if="rewardLevels.length===0" class="no-rewards-message">{{ $t('prizes.noRewards') }}</span>
            </div>
            <button
                v-if="rewardLevels.length > 0"
                class="reward-coupons-redeem-button"
                :disabled="!arePointsEnough"
                @click="redeemRewards"
            >
                {{ $t('prizes.redeem') }}
            </button>
            <span class="error-message" :class="{'hide': arePointsEnough}">{{ $t('prizes.notEnoughPointsError') }}</span>
        </div>
    </b-container>
</template>

<script>
    import rewardLevelService from '@/api/reward-levels'
    import RewardCardContainer from '@/components/RewardCardContainer'

    export default {
        name: 'Prizes',
        components: {
            RewardCardContainer
        },
        data() {
            return {
                availablePoints: 0,
                showNoPoint: false,
                rewardLevels: [],
                errorCodes: {
                    INSUFFICIENT_POINTS: this.$i18n.t('prizes.insufficientPointsError'),
                    FAILED_SMS_DELIVERY: this.$i18n.t('prizes.failedSmsDeliveryError'),
                    PLAYER_NOT_LOGGED_IN: this.$i18n.t('prizes.playerNotLoggedInError'),
                    REWARD_LEVEL_NOT_FOUND: this.$i18n.t('prizes.rewardLevelNotFoundError'),
                    REWARD_CODE_NOT_FOUND: this.$i18n.t('prizes.rewardCodeNotFound'),
                    REWARD_NOT_REGISTERED: this.$i18n.t('prizes.rewardNotRegistered'),
                    INVALID_MESSAGE_TEMPLATE: this.$i18n.t('prizes.invalidMessageTemplate'),
                    INVALID_PHONE_NUMBER: this.$i18n.t('prizes.invalidPhoneNumber'),
                    NULL_LIST: this.$i18n.t('prizes.nullListError'),
                    INVALID_DATA: this.$i18n.t('prizes.invalidDataError'),
                    INVALID_PLAYER_DATA: this.$i18n.t('prizes.invalidPlayerDataError')
                }
            }
        },
        computed: {
            hasMobile() {
                return this.player && this.player.phone
            },
            player() {
                return this.$store.getters.getCurrentUser
            },
            cartLength() {
                return this.$store.getters.getRewardCart.length
            },
            arePointsEnough() {
                return this.$store.getters.getRewardCartTotalPoints <= this.availablePoints
            },
            cartTotalPoints() {
                return this.$store.getters.getRewardCartTotalPoints
            }
        },
        created() {
            this.getAvailablePoints()
            this.fetchRewardLevels()
        },
        beforeDestroy() {
            this.$store.commit('clearRewardCart')
        },
        methods: {
            fetchRewardLevels() {
                rewardLevelService.getRewardsLevels().then((res) => {
                    if (res.data) {
                        this.rewardLevels = res.data.sort((a, b) => {
                            return a.points - b.points
                        })
                    }
                })
            },
            getAvailablePoints() {
                rewardLevelService.getRedeemedPoints().then((res) => {
                    this.availablePoints = res.data.availablePoints
                })
            },
            redeemRewards() {
                if (this.$store.getters.isPreviewMode) {
                    this.$store.commit('setPointsToRedeem', 100)
                    this.$store.commit('toggleShowRedeemModal')
                } else {
                    this.$store.dispatch('redeemRewards', this.errorCodes)
                }
            }
        }
    }

</script>

<style lang="scss" scoped>
.prizes-container {
    color: var(--main-content-text);
    padding: 1rem;
}

.header-top {
    margin-top: 2rem;
    text-align: left;
    border-bottom: 2px solid var(--main-content-text);
    display: flex;
    align-items: center;
    gap: 1rem;
}

.text-title-first {
    font-weight: 700;
    font-size: 1.75rem;
}

.text-title-second {
    font-weight: 700;
    font-size: 1rem;
}

.route-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    gap: 1rem;
}

.reward-coupons-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 3rem;
    padding: 2rem 0 3rem;
    min-width: 50%;
    border-bottom: 1px solid var(--main-content-text);
    width: 100%;
}

.reward-coupons-redeem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 2rem 0;
}

.reward-coupons-redeem-button {
    background-color: var(--secondary-color);
    color: var(--white);
    border: none;
    border-radius: 0.35rem;
    width: 100%;
    max-width: 300px;
    min-height: 60px;
    font-size: 1rem;
    font-weight: 700;
    margin: 1.5rem auto;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-shadow: 5px 5px 15px 5px rgb(0 0 0 / 20%);
}

.reward-coupons-redeem-button:active {
    transform: scale(0.9);
}

.reward-coupons-redeem-button:disabled {
    background-color: var(--disabled-color);
    cursor: not-allowed;
}

.vertical-align {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.error-message {
    display: block;
    color: red;
    font-weight: 700;
    font-size: 1rem;
    margin-top: 1rem;
    transition: all 0.2s ease-in-out;
}

.error-message.hide {
    display: none;
}

.no-rewards-message {
    font-weight: 700;
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

@media screen and (min-width: 992px) {
    .reward-coupons-container {
        gap: 1rem;
        justify-content: space-evenly;
    }
}

</style>
