var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reward-coupon",class:{'not-available': _vm.reward.numberOfAvailableCodes <= 0 || _vm.availableRedemptions <= 0},style:({
        backgroundImage: 'url(' + _vm.reward.imageUrl + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    })},[_c('div',{staticClass:"custom-row"},[_c('span',{staticClass:"points"},[_vm._v(_vm._s(_vm.reward.points)+" "+_vm._s(_vm.$t("prizes.points")))])]),_c('div',{staticClass:"custom-row"},[_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.reward.labelTemplate),expression:"reward.labelTemplate"}]})]),(_vm.isSending)?_c('div',{staticClass:"is-sending"},[_c('b-spinner',{attrs:{"type":"grow","size":"is-small","animation":{
                duration: 1000,
                delay: 200,
                infinite: true
            }}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }