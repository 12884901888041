<template>
    <div
        class="reward-coupon"
        :class="{'not-available': reward.numberOfAvailableCodes <= 0 || availableRedemptions <= 0}"
        :style="{
            backgroundImage: 'url(' + reward.imageUrl + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
        }"
    >
        <div class="custom-row">
            <span class="points">{{ reward.points }} {{ $t("prizes.points") }}</span>
        </div>
        <div class="custom-row">
            <div v-dompurify-html="reward.labelTemplate" />
        </div>
        <div v-if="isSending" class="is-sending">
            <b-spinner
                type="grow"
                size="is-small"
                :animation="{
                    duration: 1000,
                    delay: 200,
                    infinite: true
                }"
            />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            reward: {
                type: Object,
                required: true
            },
            availableRedemptions: {
                type: Number,
                required: true
            }
        },
        computed: {
            isSending() {
                return this.$store.getters.isSendingSms
            }
        }
    }
</script>

<style lang="scss" scoped>
.reward-coupon {
    position: relative;
    width: 100%;
    max-width: 350px;
    aspect-ratio: 3/2;
    border-radius: 1.25rem;
    padding: 1rem 2rem;
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--white);
}

.not-available {
    opacity: 0.6;
    filter: grayscale(100%);
    cursor: not-allowed;
}

.custom-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.points,
.reward-name {
    font-weight: 700;
    font-size: 1.1rem;
}

.is-sending {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    background: hsl(0deg 0% 0% / 50%);
    cursor: not-allowed;
    border-radius: 1.25rem;
}

.quantity {
    margin-bottom: 1rem;
    font-size: 2rem;
}

@media screen and (min-width: 992px) {
    .points,
    .reward-name {
        font-size: 1.25rem;
    }
}

</style>
