<template>
    <div class="reward-card-container">
        <reward-card
            :reward="reward"
            :available-redemptions="availableRedemptions"
        />
        <div class="quantity-controls">
            <div class="remove-quantity" @click="removeQuantity">
                -
            </div>
            <div class="quantity-preview">
                {{ amount }}
            </div>
            <div class="add-quantity" @click="addQuantity">
                +
            </div>
        </div>
        <div v-if="availableRedemptions" class="available-redemptions">
            {{ $t('prizes.availablePoints', {availableRedemptions}) }}
        </div>
        <div v-else class="available-redemptions">
            {{ $t('prizes.noCoupons') }}
        </div>
    </div>
</template>

<script>
    import RewardCard from '@/components/RewardCard'
    export default {
        components: {
            RewardCard
        },
        props: {
            reward: {
                type: Object,
                required: true
            },
            availablePoints: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                amount: 0
            }
        },
        computed: {
            availableRedemptions() {
                return Math.floor(this.availablePoints / this.reward.points)
            }
        },
        methods: {
            addQuantity() {
                if (this.amount < this.availableRedemptions && this.amount < this.reward.numberOfAvailableCodes) {
                    this.amount++
                    this.addItemToCart()
                }
            },
            removeQuantity() {
                if (this.amount > 0) {
                    this.amount--
                    this.removeItemFromCart()
                }
            },
            addItemToCart() {
                this.$store.commit('addRewardToCart', {
                    rewardLevel: this.reward,
                    amount: this.amount
                })
            },
            removeItemFromCart() {
                this.$store.commit('removeRewardFromCart', {
                    rewardLevel: this.reward,
                    amount: this.amount
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.reward-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    margin: 0 auto;
}

.available-redemptions {
    font-weight: 700;
    font-size: 1.1rem;
    max-width: 350px;
    text-align: left;
}

.custom-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
}

.quantity-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 120px;
    gap: 0.5rem;
}

.remove-quantity,
.add-quantity {
    font-weight: 700;
    cursor: pointer;
    font-size: 3rem;
    user-select: none;
}

.quantity-preview {
    background: var(--white);
    border-radius: 0.25rem;
    color: var(--black);
    font-weight: bold;
    font-size: 1.5rem;
    padding: 0.25rem 1rem;
}

@media screen and (min-width: 992px) {
    .available-redemptions {
        font-size: 1.25rem;
    }

    .custom-row {
        max-width: 600px;
    }

    .reward-card-container {
        align-items: flex-start;
        margin: 0;
    }
}
</style>
